import { Node } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

function debounce(callback, delay) {
  let timer;

  return (...args) => {
    return new Promise((resolve, reject) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        try {
          const output = callback(...args);
          resolve(output);
        } catch (err) {
          reject(err);
        }
      }, delay);
    });
  };
}

export const AutocompleteExtension = Node.create({
  name: "suggestion",

  addOptions() {
    return {
      applySuggestionKey: "Tab",
      suggestionDebounce: 150,
      apiEndpoint: "https://api.openai.com/v1/chat/completions",
    };
  },

  addProseMirrorPlugins() {
    const pluginKey = new PluginKey("suggestion");
    const { applySuggestionKey, suggestionDebounce } = this.options;
    let hiddenText = ""; // Store hidden text

    const getSuggestion = debounce(async (previousText, cb) => {
      try {
        const response = await fetch(this.options.apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer `, // Replace with your OpenAI API key
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content:
                  "You are an autocomplete engine that autocompletes a sentence and provides one relevant word or short sentence at a time, up to 5 words, to continue the user's descriptions for getting a gift for others. Focus on the context of gift-giving, and do not include dashes, ellipses, or complete sentences.",
              },
              { role: "user", content: previousText },
            ],
            max_tokens: 50,
            temperature: 0.7,
          }),
        });

        if (response.ok) {
          const suggestions = await response.json();
          const completion = suggestions.choices[0].message.content.trim();
          cb(completion);
        } else {
          cb(null);
        }
      } catch (error) {
        console.error("Error fetching suggestion:", error);
        cb(null);
      }
    }, suggestionDebounce);

    return [
      new Plugin({
        key: pluginKey,
        state: {
          init() {
            return DecorationSet.empty;
          },
          apply(tr, oldValue) {
            if (tr.getMeta(pluginKey)) {
              const { decorations } = tr.getMeta(pluginKey);
              return decorations;
            }
            return tr.docChanged ? oldValue.map(tr.mapping, tr.doc) : oldValue;
          },
        },
        view() {
          return {
            update(view, prevState) {
              const selection = view.state.selection;
              const cursorPos = selection.$head.pos;

              if (prevState && prevState.doc.eq(view.state.doc)) {
                return;
              }

              setTimeout(() => {
                const tr = view.state.tr;
                tr.setMeta("addToHistory", false);
                tr.setMeta(pluginKey, { decorations: DecorationSet.empty });
                view.dispatch(tr);
              }, 0);

              const previousText = view.state.doc
                .textBetween(0, view.state.doc.content.size, " ")
                .slice(-4000);

              if (previousText.trim()) {
                // Only fetch suggestion if there is text
                getSuggestion(previousText, (suggestion) => {
                  if (!suggestion) return;

                  hiddenText = suggestion; // Store the suggestion as hidden text

                  const updatedState = view.state;
                  const cursorPos = updatedState.selection.$head.pos;
                  const suggestionDecoration = Decoration.widget(
                    cursorPos,
                    () => {
                      const parentNode = document.createElement("span");
                      parentNode.innerHTML = suggestion;
                      parentNode.classList.add("hidden-autocomplete");
                      parentNode.dataset.suggestion = suggestion; // Store the suggestion in a data attribute
                      parentNode.style.cursor = "pointer"; // Ensure the cursor looks clickable

                      // Event listener for click
                      parentNode.addEventListener("click", () => {
                        const { from } = updatedState.selection;
                        const transaction = updatedState.tr.insertText(
                          hiddenText,
                          from
                        );
                        view.dispatch(transaction);
                        hiddenText = ""; // Clear the hidden text after insertion
                      });

                      return parentNode;
                    },
                    { side: 1 }
                  );

                  const decorations = DecorationSet.create(updatedState.doc, [
                    suggestionDecoration,
                  ]);
                  const tr = view.state.tr;
                  tr.setMeta("addToHistory", false);
                  tr.setMeta(pluginKey, { decorations });
                  view.dispatch(tr);
                });
              }
            },
          };
        },
        props: {
          decorations(editorState) {
            return pluginKey.getState(editorState);
          },
          handleKeyDown(view, event) {
            const { state, dispatch } = view;

            if (hiddenText && event.key === applySuggestionKey) {
              const { from } = state.selection;

              // Insert the hidden text at the current cursor position
              const transaction = state.tr.insertText(hiddenText, from);
              dispatch(transaction);

              hiddenText = ""; // Clear the hidden text after insertion
              event.preventDefault();
              return true;
            }

            return false;
          },
        },
      }),
    ];
  },
});
