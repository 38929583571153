import create from "zustand";
import { BASE_URL } from "../Global";
import useAuthStore from "./AuthStore";

const useEventStore = create((set, get) => ({
  events: [],
  spaces: [],
  loggedIn: localStorage.getItem("loggedin") === "true",
  setEvents: (events) => set({ events }),
  setSpaces: (spaces) => set({ spaces }),
  setLoggedIn: (status) => set({ loggedIn: status }),

  prompt: localStorage.getItem("prompt"),

  showPopup: false,
  popupMessage: "",
  popupImageUrl: null,
  popupLocation: "",
  popupType: "",
  setPopup: (message, imageUrl, location, type) => {
    set({
      showPopup: true,
      popupMessage: message,
      popupImageUrl: imageUrl || null,
      popupLocation: location,
      popupType: type,
    });
    setTimeout(
      () => set({ showPopup: false, popupMessage: "", popupImageUrl: null }),
      1500
    ); // Hide after 1 second
  },
  hidePopup: () =>
    set({ showPopup: false, popupMessage: "", popupImageUrl: null }),

  handleAddingNewSpaceOrEvent: async (type, event, space) => {
    const { setEvents, loggedIn } = get(); // Use get to access the store state

    if (loggedIn) {
      const response = await fetch(`${BASE_URL}/api/user/event-space`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          event: event,
          space: space,
          email: localStorage.getItem("email"),
        }),
      });
      return response;
    } else {
      const eventData = JSON.parse(localStorage.getItem("eventData")) || [];
      const spaceData = JSON.parse(localStorage.getItem("spaceData")) || [];

      if (type === "events") {
        const isDuplicateEvent = eventData.some(
          (storedEvent) =>
            storedEvent.eventName === event.eventName &&
            storedEvent.eventDate === event.eventDate
        );
        if (isDuplicateEvent) {
          return 400;
        } else {
          eventData.push(event);
          localStorage.setItem("eventData", JSON.stringify(eventData));
          console.log(
            "Saved event to localStorage:",
            JSON.stringify(eventData, null, 2)
          );
          return "successfully added";
        }
      } else if (type === "spaces") {
        const isDuplicateSpace = spaceData.some(
          (storedSpace) => storedSpace.spaceName === space.spaceName
        );
        if (isDuplicateSpace) {
          return 400;
        } else {
          spaceData.push(space);
          localStorage.setItem("spaceData", JSON.stringify(spaceData));
          console.log(
            "Saved space to localStorage:",
            JSON.stringify(spaceData, null, 2)
          );
          return "successfully added";
        }
      }
    }
  },

  handleFetchEvents: async () => {
    const { setEvents, setSpaces, loggedIn } = get(); // Use get to access the store state
    try {
      if (localStorage.getItem("loggedin") === "true") {
        const response = await fetch(`${BASE_URL}/api/user/events`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ email: localStorage.getItem("email") }),
        });
        const data = await response.json();
        setEvents(data.events);
        setSpaces(data.spaces);
      } else {
        const eventData = localStorage.getItem("eventData");
        if (eventData) {
          setEvents(JSON.parse(eventData));
        }
        const spaceData = localStorage.getItem("spaceData");
        if (spaceData) {
          setSpaces(JSON.parse(spaceData));
        }
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  },

  addProductToEvent: async (type, name, giftID) => {
    const { events, spaces, loggedIn } = get(); // Use get to access the store state
    // const eventName = "aboahmeden";
    const { prompt } = get();
    try {
      if (localStorage.getItem("loggedin") === "true") {
        const response = await fetch(`${BASE_URL}/api/user/events/update/add`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            // index,
            type: type,
            giftID: [giftID, window.localStorage.getItem("prompt")],
            name,
            email: localStorage.getItem("email"),
          }),
        });

        if (response.ok) {
          // Handle success
        } else {
          console.error("Failed to update event data.");
        }
      } else {
        const updatedItems = type === "events" ? [...events] : [...spaces];
        const existingItem = updatedItems.find(
          (item) =>
            (type === "events" ? item.eventName : item.spaceName) === name
        );

        if (existingItem) {
          const updatedGiftID = [
            ...(existingItem.giftID || []),
            [giftID, prompt],
          ];
          existingItem.giftID = updatedGiftID;
          localStorage.setItem(
            type === "events" ? "eventData" : "spaceData",
            JSON.stringify(updatedItems)
          );
          set({ [type === "events" ? "events" : "spaces"]: updatedItems });
        } else {
          console.error(
            `Item with the specified name does not exist in ${type}.`
          );
        }
      }
    } catch (error) {
      console.error("Error updating event data:", error);
    }
  },

  removeProductFromEvent: async (type, name, giftID) => {
    const { events, spaces, loggedIn } = get(); // Use get to access the store state
    // const eventName = "aboahmeden";
    const { prompt } = get();
    try {
      if (localStorage.getItem("loggedin") === "true") {
        const response = await fetch(
          `${BASE_URL}/api/user/events/update/delete`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              // index,
              type: type,
              giftID: [giftID],
              name,
              email: localStorage.getItem("email"),
            }),
          }
        );

        if (response.ok) {
          // Handle success
        } else {
          console.error("Failed to delete product from event.");
        }
      } else {
        const updatedItems = type === "events" ? [...events] : [...spaces];
        const existingItemIndex = updatedItems.findIndex(
          (item) =>
            (type === "events" ? item.eventName : item.spaceName) === name
        );

        if (existingItemIndex !== -1) {
          const existingItem = updatedItems[existingItemIndex];
          const updatedGiftID =
            existingItem.giftID?.filter((ids) => !ids.includes(giftID)) || [];
          existingItem.giftID = updatedGiftID;
          localStorage.setItem(
            type === "events" ? "eventData" : "spaceData",
            JSON.stringify(updatedItems)
          );
          set({ [type === "events" ? "events" : "spaces"]: updatedItems });
        } else {
          console.error(
            `Item with the specified name does not exist in ${type}.`
          );
        }
      }
    } catch (error) {
      console.error("Error deleting product from event:", error);
    }
  },

  // removeProductFromEvent: async (eventName, giftID) => {
  //   const { events, loggedIn } = get(); // Use get to access the store state

  //   // const eventName = "aboahmeden";

  //   try {
  //     if (loggedIn) {
  //       const response = await fetch(
  //         `${BASE_URL}/api/user/events/update/delete`,
  //         {
  //           method: "PUT",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           },
  //           body: JSON.stringify({
  //             // index,
  //             giftID,
  //             eventName,
  //             email: localStorage.getItem("email"),
  //           }),
  //         }
  //       );

  //       if (response.ok) {
  //         // Handle success
  //       } else {
  //         console.error("Failed to update event data.");
  //       }
  //     } else {
  //       const updatedEvents = [...events];

  //       const existingEvent = updatedEvents.find(
  //         (event) => event.eventName === eventName
  //       );

  //       if (existingEvent) {
  //         const updatedEvents = events.map((event) => {
  //           if (event.eventName === eventName) {
  //             const updatedGiftID =
  //               event.giftID?.filter((ids) => !ids.includes(giftID)) || [];
  //             return { ...event, giftID: updatedGiftID };
  //           }
  //           return event;
  //         });
  //         localStorage.setItem("eventData", JSON.stringify(updatedEvents));
  //         set({ events: updatedEvents });
  //       } else {
  //         console.error("Event with the specified eventName does not exist.");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error updating event data:", error);
  //   }
  // },

  likeGift: async (giftID) => {
    try {
      await fetch(`${BASE_URL}/api/gifts/like`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ giftID }),
      });
    } catch (error) {
      console.error("Error liking gift:", error);
    }
  },
}));

export default useEventStore;
