// appStore.js
import create from "zustand";
import { BASE_URL_A, BASE_URL } from "../Global.js";

const useAppStore = create((set, get) => ({
  // Added 'get' to the function parameters to fix the 'get is not defined' error
  searchText: "", // searchText is initialized as an empty string in the store.
  setSearchText: (text) => set({ searchText: text }), // Function to update searchText.

  gifts: [], // gifts is initialized as an empty array.
  setGifts: (gifts) => set({ gifts }), // Function to update the gifts array.

  windowWidth: window.innerWidth, // windowWidth is initialized with the width of the browser window.
  setWindowWidth: (width) => set({ windowWidth: width }), // Function to update windowWidth.

  error: null, // error is initialized as null, meaning no error initially.
  setError: (error) => set({ error }), // Function to update the error state.

  mounted: false, // mounted is initialized as false, indicating the component is not yet mounted.
  setMounted: (isMounted) => set({ mounted: isMounted }), // Function to update the mounted state.

  editorText: "", // editorText is initialized as an empty string.
  setEditorText: (text) => set({ editorText: text }), // Function to update editorText.

  count: localStorage.getItem("count") || 0, // Initialize count by fetching from local storage or default to 0
  setCount: (count) => set({ count: count }),

  incrementCount: () => {
    const currentCount = parseInt(get().count);
    const newCount = currentCount + 1;
    set({ count: newCount });
    localStorage.setItem("count", newCount);
  },

  placeholders: [
    // Array of placeholder texts for different locales.
    "Describe the Person or the Event you're trying to get a gift for ...",
    "Eg: I would like to get my mom a gift that shows how much I love her. She likes cooking and music. My budget is 50 Euros. ",
    "Eg: My Mother's birthday is coming up, and she is really into cooking and shopping, she is 47 years old, and my budget is 100$",
    "Eg: I would like to gift my little brother something for graduating from school, he is 18 years old, and is really into biking.",
    "Eg: Grandma is in her 70s, and i would like to get her something that shows how much i appreciate her presence in my life, in the range of 500 EGPs",
  ],
  placeholderIndex: 0, // placeholderIndex is initialized as 0, pointing to the first placeholder text.
  setPlaceholderIndex: (index) => set({ placeholderIndex: index }), // Function to update placeholderIndex.

  // New function to rotate placeholders
  rotatePlaceholder: () => {
    const currentIndex = get().placeholderIndex;
    const nextIndex = (currentIndex + 1) % get().placeholders.length;
    set({ placeholderIndex: nextIndex });
  },

  isFocused: false, // isFocused is initialized as false, indicating the input field is not focused.
  setIsFocused: (focused) => set({ isFocused: focused }), // Function to set the focus state.

  endRef: null, // endRef is initialized as null. It's a reference that might be used for scrolling or focusing.

  storedValue: localStorage.getItem("prompt"), // storedValue retrieves a value from local storage, if it exists.

  show: false, // show is initialized as false, used to control visibility of certain UI elements.
  setShow: (show) => set({ show }), // Function to update the show state.

  tag: "", // tag is initialized as an empty string.
  setTag: (tag) => set({ tag }), // Function to update the tag.

  isHovered: false, // isHovered is initialized as false, indicating that an element is not being hovered over.
  setIsHovered: (hovered) => set({ isHovered: hovered }), // Function to set the hover state.

  isExpanded: false, // isExpanded is initialized as false, used to control the expansion state of UI elements.
  setIsExpanded: (expanded) => set({ isExpanded: expanded }), // Function to update the expansion state.
  // Async search function
  isSearching: false, // isSearching is initialized as false, indicating that a search is not in progress.
  setIsSearching: (searching) => set({ isSearching: searching }), // Function to set the search state.

  searchError: null, // searchError is initialized as null, meaning no error initially.
  setSearchError: (error) => set({ searchError: error }), // Function to update the search error state.
  // Define an asynchronous function to handle the search process

  handleSearch: async (scroll) => {
    // Set the isSearching state to true to indicate that a search operation is in progress
    set({ isSearching: true });
    // Extract the IDs from the gifts array to use in the search query
    const ids = get().gifts.map((gift) => gift._id);

    try {
      // Perform a PUT request to the specified API endpoint with the gift IDs
      const response = await fetch(`${BASE_URL}api/gifts/searches`, {
        method: "PUT",
        body: JSON.stringify({
          giftids: ids,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      // If the response is not OK, throw an error
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      // If an error occurs, set the searchError state with the caught error
      set({ searchError: error });
    } finally {
      // Finally, set the isSearching state to false to indicate the search operation has ended
      set({ isSearching: false });
      if (scroll) {
        window.scrollTo(0, 0);
      }
    }
  },

  demoifySearchText: (newText) => {
    const modifiedText = newText.replace(
      /{([^}]+)}/g,
      '<span data-type="mention" class="mention" data-id="1" data-label="$1">$1</span>'
    );
    set({ searchText: `<p>${modifiedText}</p>` });
    console.log(`<p>${modifiedText}</p>`);
    return `<p>${modifiedText}</p>`;
  },

  // Define an asynchronous function to fetch the desired gifts based on the searchText
  getWantedGifts: async (userInfo, scroll) => {
    set({ isSearching: true });
    console.log(get().searchText);
    // modified Searchtext without the <span> element
    const modifiedSearchText = get()
      .searchText.replace(/<span[^>]*>/g, "{")
      .replace(/<\/span>/g, "}")
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "");
    // Store the current searchText in localStorage under the key "prompt"
    localStorage.setItem("prompt", modifiedSearchText);

    // Prepare the parameters for the API request using the current searchText
    const params = {
      text: get().searchText,
    };
    console.log();

    // set({ searchText: window.localStorage.getItem("searchText") || "" });
      

    try {
      let userLocation = 
      ( 
       userInfo["X-countryName"] || "worldwide" 
      ).toLowerCase();
      // Perform two simultaneous POST requests to different API endpoints
      const [miniDetectionResponse, wpwResponse] = await Promise.all([
        fetch(`${BASE_URL_A}`, {
          method: "POST",
          body: JSON.stringify({
            text: modifiedSearchText,
            index_name: "gifts",
            event_type: "Search",
            userLocation: userLocation,
            minScore: 5,
            query_param: {
              "user": {"active": true, "k_value": 100, "boost": 10},
              "tags": {"active": true, "k_value": 100, "boost": 5},
              "GPT":  {"active": true, "k_value": 100, "boost": 5},
              "assort_based_clicks": false
          }
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }),
        fetch(`${BASE_URL}api/wpw/add`, {
          method: "POST",
          body: JSON.stringify({
            text: modifiedSearchText,
            userInfo: userInfo,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }),

        fetch(
          `${BASE_URL}api/wpw/add`,
          {
            method: "POST",
            body: JSON.stringify({
              text: modifiedSearchText,
              userInfo: userInfo,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        ),
      ]);
      // Process the response from the miniDetection API to extract gift data
      var miniDetectionGifts = await miniDetectionResponse.json();
      // Map the received gifts to a new array with a specific structure
      const newArray = miniDetectionGifts.map((item) => {
        return {
          _id: item._source.mid,
          opId: item._id,
          title: item._source.title,
          link: item._source.link,
          price: Math.ceil(item.fields.modified_price[0]),
          img: item._source.img,
          country: item._source.country,
          loveMeter: item._source.loveMeter,
          categories: item._source.categories,
        };
      });
      // Update the gifts state with the newly structured array
      set({ gifts: newArray });
      // Call the handleSearch function to perform a search with the updated gifts
      get().handleSearch(scroll);
      // window.localStorage.setItem("prompt", get().searchText);
      // Scroll to top after data is set
      // window.scrollTo(0, 0);
    } catch (error) {
      // If an error occurs during the fetch operations, set the searchError state with the caught error
      set({ searchError: error });
    }
  },

  // getSuggestedGifts: async (GiftTitles) => {
  //   set({ isSearching: true });

  //   try {
  //     // Perform two simultaneous POST requests to different API endpoints
  //     const [miniDetectionResponse, wpwResponse] = await Promise.all([
  //       fetch(`${BASE_URL_A}`, {
  //         method: "POST",
  //         body: JSON.stringify({
  //           text: GiftTitles,
  //           index_name: "gifts",
  //           k: 20,
  //           event_type: "Search",
  //         }),
  //         headers: {
  //           "Content-type": "application/json; charset=UTF-8",
  //         },
  //       }),
  //       fetch(
  //         "https://ds5p34fp5g.execute-api.us-east-1.amazonaws.com/api/wpw/add",
  //         {
  //           method: "POST",
  //           body: JSON.stringify({
  //             text: GiftTitles,
  //           }),
  //           headers: {
  //             "Content-type": "application/json; charset=UTF-8",
  //           },
  //         }
  //       ),
  //     ]);
  //     // Process the response from the miniDetection API to extract gift data
  //     var miniDetectionGifts = await miniDetectionResponse.json();
  //     // Map the received gifts to a new array with a specific structure
  //     const newArray = miniDetectionGifts.map((item) => {
  //       return {
  //         _id: item._source.mid,
  //         title: item._source.title,
  //         link: item._source.link,
  //         price: item._source.price,
  //         img: item._source.img,
  //         country: item._source.country,
  //         loveMeter: item._source.loveMeter,
  //         categories: item._source.categories,
  //       };
  //     });
  //     // Update the gifts state with the newly structured array
  //     set({ gifts: newArray });
  //     get().handleSearch("");
  //   } catch (error) {
  //     // If an error occurs during the fetch operations, set the searchError state with the caught error
  //     set({ searchError: error });
  //   }
  // },
}));

export default useAppStore;