import React, { createContext, useContext, useState } from "react";

const MentionContext = createContext(); // Create a new context

export const MentionProvider = ({ children }) => {
  const [mention, setMention] = useState(null); // State to hold current mention data

  // Function to handle inserting mention into the editor
  const handleInsertMention = (mentionData) => {
    setMention(mentionData);
  };

  return (
    <MentionContext.Provider value={{ mention, handleInsertMention }}>
      {children}
    </MentionContext.Provider>
  );
};

export const useMention = () => {
  const context = useContext(MentionContext);
  if (!context) {
    throw new Error("useMention must be used within a MentionProvider");
  }
  return context;
};
