import React, { useState } from "react";
import "../Styles/ProductsMini.scss";
import prodImg from "../../Assets/Images/image-10-3.png";
import LikeButton from "./Hearts.jsx";
// import Likes from "./Likes.jsx";
import Likes from "./Favorite.jsx";
import GfitRobot from "./GiftRobot.jsx";

import { AiOutlineAmazon } from "react-icons/ai";
import { GoGift } from "react-icons/go";
import Favorite from "./Favorite.jsx";
import useAppStore from "../../Store/Store.jsx";
import { useMention } from "../SearchArea/MentionContext.jsx";
import { MentionProvider } from "../SearchArea/MentionContext.jsx";
import Cookies from "js-cookie";


import { BASE_URL } from "../../Global.js";

// Main functional component for displaying product details
function Products(props) {
  const { handleInsertMention } = useMention(); // Access handleInsertMention function
  const cookie = Cookies.get();
  const userLocation = cookie["X-countryName"];
  const [value, setValue] = useState(props.value || "");
  const { tag, setTag } = useAppStore();
  // Handles input changes and propagates them upwards via props.onChange
  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange?.(event.target.value);
  };
  const [openComponentID, setOpenComponentID] = useState(null);

  // Determine country flag and currency based on props.country
  let countryflag = "";
  let currency = "€";
  switch (userLocation?.toLowerCase()) {
    case "germany":
      countryflag =
        "http://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg";
      break;
    case "france":
      countryflag =
        "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg";
      break;
    case "united arab emirates":
      countryflag =
        "http://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg";
      currency = "AED";
      break;
    case "egypt":
      // countryflag =
      // "http://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg";
      currency = "EGP";
      break;

    default:
      countryflag =
        "http://purecatamphetamine.github.io/country-flag-icons/3x2/EU.svg";
  }

  // Determines if the link is an Amazon link and returns appropriate icon
  function isAmazonLink(link) {
    if (link?.includes("amazon")) {
      console.log("Amazon link found");
      return <AiOutlineAmazon />;
    }
    return <GoGift />;
  }

  // Logs the clicked product for analytics
  const saveClickedProduct = async () => {


    const params = {
      giftId: props.id,
      searchSentence: localStorage.getItem("prompt"),

      giftIdMD: props.id,
      giftIdOP: props.opId,
    };

    try {
      await fetch(`${BASE_URL}api/gifts/click`, {
        method: "PUT",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      });

    } catch (error) {
      console.error("Error logging clicked product:", error);
    }
  };

  // Handles redirection to the product URL and logs the product click
  const handleRedirect = () => {
    window.open(props.url, "_blank");
    saveClickedProduct();
  };

  // const handleClickTag = async (id, tag) => {
  //   setTag(tag);
  // };

  // Handles tag clicks, fetches related sentences, and updates the searchText in the store
  const handleClickTag = async (id, tag) => {
    try {
      const response = await fetch(`${BASE_URL}api/gifts/prompt`, {

        method: "POST",
        body: JSON.stringify({
          giftId: id,
          text: localStorage.getItem("prompt"),
          tag: tag,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const { generatedSentences } = await response.json();
      if (typeof props.handleSearchTextChange === "function") {
        // props.handleSearchTextChange(text); // Update searchText in the store with the returned text
        setTag(generatedSentences);
        console.log(generatedSentences);
      } else {
        console.error("handleSearchTextChange is not a function");
      }
    } catch (error) {
      console.error("Error handling tag click:", error);
    }
  };

  return (
    <MentionProvider>
      <div className="container_shadowborder-solid border-1s border-neutral-green-100 flex flex-col px-3 pb-3 text-base font-bold font-sans leading-6 bg-[#EBEBE9] rounded-[20px] max-w-[250px] mb-4 sm:m-2 md:m-6 shadow-md w-full">
        <div className=" flex justify-center">
          <div className="relative">
            <div className="absolute top-4 right-[-230px] ">
              {/* <Favorite
                gift_id={props.id}
                gift_image={props.image}
                isOpen={openComponentID === props.id}
                setOpenComponentID={setOpenComponentID}
              /> */}
            </div>
          </div>
          <img
            src={props.image}
            alt="Product Image"
            className="rounded-t-[20px] rounded-r-[00px] rounded-l-[00px] max-w-[250px]"
            onClick={handleRedirect}
          />
        </div>

        <div className="flex gap-5 justify-between px-2.5 pt-2.5 text-md font-bold font-sans text-red-300 my-2">
          <div>
            <span>{props.price}</span>
            {currency}
          </div>
          <LikeButton lovemeter={props.lovemeter}></LikeButton>
        </div>
        <div className="mt-2.5 text-center text-neutral-green-500 mb-3">
          <h2 onClick={handleRedirect}>{props.title}</h2>
        </div>

        <div className="flex flex-wrap mx-2 mb-4">
          {props.categories?.slice(12, 19).map((tag, index) => (
            <button
              key={index}
              // className="px-1 py-0.5 mx-1 my-1 mt-1 text-center text-xs text-red-50 whitespace-nowrap bg-red-200 leading-[150%] rounded-[100px] shadow-sm hover:bg-grey-500 hover:text-red-500 border-2 border-red-200 hover:border-red-500 hover:cursor-pointer"
              className="px-1 py-0.5 mx-1 my-1 mt-1 text-center text-xs text-red-300 whitespace-nowrap bg-grey-500 leading-[150%] rounded-[100px] shadow-sm hover:bg-red-400 hover:text-red-50 border-2 border-red-200 hover:border-red-400 hover:cursor-pointer"
              onClick={() => handleClickTag(props.id, tag)}
            >
              #{tag}
            </button>
          ))}
        </div>
        <div
          className="justify-center items-center px-16 py-2 mx-2 text-center text-red-50 whitespace-nowrap bg-red-400 leading-[150%] rounded-[100px] shadow-sm hover:bg-grey-500 hover:text-red-500 border-2 border-red-400 hover:border-red-500 hover:cursor-pointer"
          onClick={handleRedirect}
        >
          Buy Gift
        </div>
      </div>
    </MentionProvider>
  );
}

export default Products;
