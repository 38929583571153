import { Card, CardContent } from "../ui/Card";
import SpaceshipImage from "../../Assets/Images/Spaceship-transformed.avif";
import ReachImage from "../../Assets/Images/Reach-transformed.avif";
import BagImage from "../../Assets/Images/TheBag-transformed.avif";

export default function SecondSection() {
  return (
    <section className="w-full py-12 md:py-24 lg:py-32 rounded-t-0 rounded-b-[20px] bg-gradient-to-b from-grey-100 via-red-100 to-red-400">
      <div className="container px-4 md:px-6">
        <h2 className="text-2xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8 bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-orange-600">
          Why Bloom With Us?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {renderCard(
            "Electrify Your Reach",
            "Spark connections with a broader audience, illuminating your product's presence in the market.",
            ReachImage
          )}
          {renderCard(
            "Empathetic Alchemy",
            "Transform your offerings through our unique empathetic annotation, turning products into pure gold.",
            BagImage
          )}
          {renderCard(
            "Hypergrowth Ignition",
            "Blast off into new markets with accelerated penetration and stratospheric sales growth.",
            SpaceshipImage
          )}
        </div>
      </div>
    </section>
  );
}

const renderCard = (title, description, imageUrl) => (
  <Card className="bg-white/80 backdrop-blur-sm hover:shadow-lg transition-shadow">
    <CardContent className="p-6 flex flex-col items-center text-center space-y-4">
      <img src={imageUrl} className="w-full h-[calc(100vw * 1.5)] rounded-xl" />
      <h3 className="text-xl font-bold">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </CardContent>
  </Card>
);
