import { create } from "zustand";
import { BASE_URL } from "../Global.js";

// Utility functions for saving and loading from localStorage
const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
const loadFromLocalStorage = (key) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : null;
};

// A Zustand store to manage event-related state
const useEventStore = create((set, get) => ({
  // State variables
  events: [],
  setEvents: (newEvents) => set({ events: newEvents }),

  spaces: [],
  setEvents: (newSpaces) => set({ spaces: newSpaces }),

  featuredEvents: [],
  setFeaturedEvents: (newEvents) => set({ events: newEvents }),

  selectedEventName: loadFromLocalStorage("selectedEventName") || null,
  setselectedEventName: (name) => set({ selectedEvent: name }),

  selectedEvent: loadFromLocalStorage("selectedEvent") || null, // Load the selected event from localStorage or set to null if not found
  setSelectedEvent: (event) => set({ selectedEvent: event }),

  selectedEventDetails: loadFromLocalStorage("selectedEventDetails") || null, // Load the selected event from localStorage or set to null if not found
  setSelectedEventDetails: (eventDetails) =>
    set({ selectedEventDetails: eventDetails }),

  gifts: [],
  setGifts: (newGifts) => set({ gifts: newGifts }),

  giftPrompts: [],
  setGiftPrompts: (newPrompt) => set({ giftPrompts: newPrompt }),

  selectedGift: null,
  setSelectedGift: (gift) => set({ selectedGift: gift }),

  email: "",
  setemail: (email) => set({ email: email }), // Function to update the Username.

  description: "",
  setDescription: (description) => set({ description: description }), // Function to update description.

  openComponentID: null, // ID of the currently open component
  setOpenComponentID: (id) => set({ openComponentID: id }),

  // originalEventName: loadFromLocalStorage("originalEventName") || "",
  // setOriginalEventName: (originalEventName) => set({ originalEventName: originalEventName }), // Function to update the Username.

  // Action to fetch events, organizer, and description from an API
  fetchEvents: async () => {
    try {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const response = await fetch(`${BASE_URL}/api/user/profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();

      // Update the 'events' state with the data received from the API
      set({ events: data.events });
      set({ spaces: data.spaces });
      set({ email: data.email });
    } catch (error) {
      console.error("Failed to fetch events:", error); // Handle errors if the API call fails
    }
  },

  // Action to Add events
  handleAddEvent: async (event) => {
    try {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const response = await fetch(`${BASE_URL}/api/user/event-space`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          event: event,

          email: localStorage.getItem("email"),
        }),
      });
      if (response) {
        get().fetchEvents();
      }
    } catch (error) {
      console.error("Failed to fetch events:", error); // Handle errors if the API call fails
    }
  },

  // Action to delete event
  handleDeletingEventsOrSpaces: async (type, name) => {
    try {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const response = await fetch(
        `${BASE_URL}/api/user/events-spaces/delete`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email, name, type }),
        }
      );
      if (response.status === 200) {
        console.log(`${name} of type ${type} has been deleted`);
        get().fetchEvents();
      }
      const data = await response.json();
    } catch (error) {
      console.error("Failed to fetch events:", error); // Handle errors if the API call fails
    }
  },

  // Action to fetch Featured/Upcoming event and its description from an API
  fetchFeaturedEvents: async () => {
    try {
      const email = localStorage.getItem("email");
      const response = await fetch(`${BASE_URL}/api/user/upcoming-events`, {
        method: "GET", // Changed method to GET
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log(data);
      // Update the 'events' state with the data received from the API
      set({ featuredEvents: data });
    } catch (error) {
      console.error("Failed to fetch events:", error); // Handle errors if the API call fails
    }
  },

  // Action to select an event based on its name
  selectEvent: (type, eventName) => {
    set((state) => {
      // Find the event with the matching name from the 'events' array
      const event = state.events.find((e) => e.eventName === eventName);
      set({ selectedEventName: eventName });
      // saveToLocalStorage("selectedEventName", eventName);
      get().fetchEventOrSpaceDetails(type, eventName);

      // saveToLocalStorage("selectedEvent", event);
      // Save the selected event to localStorage

      return { selectedEvent: event }; // Update selectedEvent to the found event
    });
  },

  // // Function to filter events by eventName
  // filterEventsByName: (eventName) => {
  //   return get().events.filter((event) => event.name === eventName);
  // },

  // // Action to clear the selected event (e.g., when navigating away from the event detail page)
  // clearSelectedEvent: () => {
  //   // Reset 'selectedEvent' to null
  //   set({ selectedEvent: null });
  // },

  // // Function to fetch a given Event's details
  // fetcheventdetails: async (eventName) => {
  //   console.log("eventnamefrom store:", eventName);
  //   // localStorage.setItem("originalEventName", eventName);
  //   // set({originalEventName: eventName})
  //   try {
  //     const token = localStorage.getItem("token");
  //     const email = localStorage.getItem("email");
  //     const response = await fetch(`${BASE_URL}/api/user/event/${eventName}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({ email }),
  //     });
  //     const eventDetail = await response.json();
  //     console.log("eventdetails", eventDetail);
  //     const giftIDs = eventDetail.giftID
  //       ? eventDetail.giftID.map((ids) => ids[0])
  //       : [];

  //     console.log("gift_ids", giftIDs);
  //     get().fetchGifts(giftIDs);
  //     console.log(eventDetail);
  //     set({ selectedEventDetails: eventDetail });
  //     // set({ selectedEventDetails: giftPrompts });

  //     saveToLocalStorage("selectedEventDetails", eventDetail);

  //     // Assuming set is a function to update the store with 'eventDetails' data
  //     console.log("eventdtailsfrom store:", eventDetail);
  //     return eventDetail; // Allow the function to return the fetched data
  //   } catch (error) {
  //     console.error("Error fetching event details:", error);
  //   }
  // },

  //   // Function to fetch a given Event's details
  //   fetchspacedetails: async (spaceName) => {
  //     console.log("eventnamefrom store:", spaceName);
  //     // localStorage.setItem("originalEventName", eventName);
  //     // set({originalEventName: eventName})
  //     try {
  //       const token = localStorage.getItem("token");
  //       const email = localStorage.getItem("email");
  //       const response = await fetch(`${BASE_URL}/api/user/space/${spaceName}`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify({ email }),
  //       });
  //       const spaceDetail = await response.json();
  //       console.log("eventdetails", spaceDetail);
  //       const giftIDs = spaceDetail.giftID
  //         ? spaceDetail.giftID.map((ids) => ids[0])
  //         : [];

  //       console.log("gift_ids", giftIDs);
  //       get().fetchGifts(giftIDs);
  //       console.log(spaceDetail);
  //       set({ selectedEventDetails: spaceDetail });
  //       // set({ selectedEventDetails: giftPrompts });

  //       saveToLocalStorage("selectedEventDetails", spaceDetail);

  //       // Assuming set is a function to update the store with 'eventDetails' data
  //       console.log("eventdtailsfrom store:", spaceDetail);
  //       return spaceDetail; // Allow the function to return the fetched data
  //     } catch (error) {
  //       console.error("Error fetching event details:", error);
  //     }
  //   },

  fetchEventOrSpaceDetails: async (type, name) => {
    // console.log("eventnamefrom store:", spaceName);
    // localStorage.setItem("originalEventName", eventName);
    // set({originalEventName: eventName})
    try {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const response = await fetch(
        `${BASE_URL}/api/user/gifts/${type}/${name}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email }),
        }
      );
      const details = await response.json();
      console.log("details", details);
      const giftIDs = details.giftID ? details.giftID.map((ids) => ids[0]) : [];

      console.log("gift_ids", giftIDs);
      get().fetchGifts(giftIDs);
      console.log(details);
      // set({ selectedEventDetails: spaceDetail });
      // set({ selectedEventDetails: giftPrompts });

      // saveToLocalStorage("selectedEventDetails", spaceDetail);

      // Assuming set is a function to update the store with 'eventDetails' data
      return details; // Allow the function to return the fetched data
    } catch (error) {
      console.error("Error fetching event details:", error);
    }
  },

  // Function to fetch all Gift Details based of the GiftIDs
  fetchGifts: async (giftIDs) => {
    try {
      const response = await fetch(`${BASE_URL}/api/gifts/filter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          attribute: "_id",
          value: giftIDs,
        }),
      });
      const gifts = await response.json();
      console.log(gifts);
      set({ gifts: gifts }); // Assuming set is a function to update the store with 'gifts' data
    } catch (error) {
      console.error("Error fetching gifts:", error);
    }
  },

  // Function to ADD Gift from Event based on GiftID, and EventName
  handleGiftAddition: async (type, name, giftID) => {
    try {
      const response = await fetch(`${BASE_URL}/api/user/events/update/add`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          type,
          giftID: [giftID],
          name,
          email: localStorage.getItem("email"),
        }),
      });

      if (response.ok) {
        get().fetchEventOrSpaceDetails(type, get().selectedEventName);
      } else {
        console.error("Failed to update event data.");
      }
    } catch (error) {
      console.error("Error updating event data:", error);
    }
  },

  // Function to delete Gift from Event based on GiftID, and EventName
  handleGiftDeletion: async (type, name, giftID) => {
    "";
    console.log(type, name, giftID);
    try {
      const response = await fetch(
        `${BASE_URL}/api/user/events/update/delete`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
            type,
            giftID,
            name,
            email: localStorage.getItem("email"),
          }),
        }
      );

      if (response.ok) {
        get().fetchEventOrSpaceDetails(type, get().selectedEventName);
      } else {
        console.error("Failed to update event data.");
      }
    } catch (error) {
      console.error("Error updating event data:", error);
    }
  },

  handleEdit: async (type, name, updatedData) => {
    try {
      const response = await fetch(`${BASE_URL}/api/user/update/${name}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          type,
          name,
          updatedData,
          email: localStorage.getItem("email"),
        }),
      });

      if (response.ok) {
        get().fetchEventOrSpaceDetails(type, get().selectedEventName);
      } else {
        console.error("Failed to update event data.");
      }
    } catch (error) {
      console.error("Error updating event data:", error);
    }
  },
}));

export default useEventStore;
