import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "../Global";
import { sendRatingFeedback } from "../Services/api";

// Custom Hook for managing feedback state and actions
const useFeedback = () => {
  // State variables for feedback management
  const [selectedRating, setSelectedRating] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [cookieValue, setCookieValue] = useState({});



  // Function to handle user rating click
  const handleRatingClick = (label, id) => {
    setSelectedRating(id);
    setIsSubmitted(true);
    if (id > 3) {
      sendRating(label, id);
      setShowThankYou(true);
    } else {
      setShowFeedbackMessage(true);
    }
  }; 

  // Function to send rating feedback
  const sendRating = async (id, feedback = "") => {
    try {
      const response = await sendRatingFeedback(id, cookieValue, "recommendation", feedback);
      if (response.status === 200) {
        setIsSubmitted(true);
        setShowThankYou(true);
      } else {
        console.error("Error submitting feedback:", response);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  // Function to handle feedback submission
  const handleFeedbackSubmit = () => {
    sendRating(
     selectedRating,
      feedback
    );
    setFeedbackSubmitted(true);
    setShowThankYou(true);
    setShowFeedbackMessage(false);
    setFeedback("");
  };

  // Effect to manage thank you message and feedback message display
  useEffect(() => {
    if (showThankYou || showFeedbackMessage) {
      const timer = setTimeout(() => {
        setShowThankYou(false);
        setShowFeedbackMessage(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [showThankYou, showFeedbackMessage]);

  useEffect(() => {
    setCookieValue(Cookies.get() || {}); // Replace with your cookie logic
  }, []);

  // Return the necessary state variables and functions for feedback management
  return {
    selectedRating,
    isSubmitted,
    showThankYou,
    showFeedbackMessage,
    feedback,
    feedbackSubmitted,
    setFeedback,
    handleRatingClick,
    handleFeedbackSubmit,
  };
};

export default useFeedback;
