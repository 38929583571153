import React, { useState } from "react";
import FacesDisplay from "./FacesDisplay";
import FeedbackForm from "./FeedbackForm";
import ThankYouMessage from "./ThankYouMessage";
import useFeedback from "../../Hooks/useFeedback";

const RatingFaces = () => {
  const {
    selectedRating,
    isSubmitted,
    showThankYou,
    showFeedbackMessage,
    feedback,
    feedbackSubmitted,
    setFeedback,
    handleRatingClick,
    handleFeedbackSubmit,
  } = useFeedback();

  return (
    <div
      className={`flex flex-col justify-center items-center space-x-4 relative overflow-visible border-solid ${
        isSubmitted ? "" : "m-5"
      }`}
    >
      {!isSubmitted ? (
        <>
          <div className="text-red-600 text-xl font-semibold">
            How would you rate the results of your search?
          </div>
          <FacesDisplay
            selectedRating={selectedRating}
            onRatingClick={handleRatingClick}
          />
        </>
      ) : (
        <>
          {showThankYou && <ThankYouMessage selectedRating={selectedRating} />}
          {selectedRating <= 3 && !feedbackSubmitted && (
            <FeedbackForm
              feedback={feedback}
              setFeedback={setFeedback}
              onSubmit={handleFeedbackSubmit}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RatingFaces;
