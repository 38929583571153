import React, { useState } from "react";
import "../Styles/Loader.scss";
import LoaderImg from "../../Assets/Images/loaderGif.gif";

function Loader() {
  return (
    <div className="loader-container">
      <img src={LoaderImg} alt="Loader" className="loader-img" />
    </div>
  );
}
export default Loader;
