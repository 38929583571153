import React from "react";
import useAuthStore from "../../Store/AuthStore";

const Popup = ({ message, imageUrl, location, type }) => {
  const { isAuthenticated } = useAuthStore();
  return (
    <div
      className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-red-300 text-grey-500 p-2.5 rounded-3xl shadow-lg flex items-center space-x-2 z-10"
      role="alert"
    >
      {imageUrl && (
        <img
          src={imageUrl}
          alt="Gift"
          className="w-10 h-10 object-cover rounded-full"
        />
      )}
      <span>{message}</span>
      {location && !isAuthenticated && <span className="pr-1">{location}</span>}
      {location && isAuthenticated && (
        <a
          href={`/${type}/${location}`}
          className="underline font-bold pr-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          {location}
        </a>
      )}
    </div>
  );
};

export default Popup;
