import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../Assets/Images/GiftLogo.png";
import dropdown from "../../Assets/Images/dropdown.png";
import "../Styles/Footer.scss";
import giftsbotfloat from "../../Assets/Images/Ciqler footer logo.png";
import mail from "../../Assets/Images/mail.png";
import twitter from "../../Assets/Images/twitter.png";
import instagram from "../../Assets/Images/insta.png"

const Navbar = () => {
  const history = useHistory();
  const handleClick = () => {
    console.log("Image clicked!");
  };
  return (
    <div className="fullFooter">
      <div className="footer-bar bg-green-100">
        <div className="floating-img-footer">
          <img
            className="gift-bot-logo-01-3-footer"
            src={giftsbotfloat}
            alt="floataya"
          />
        </div>
        <p className="p-footer">© 2023 Ciqler. All rights reserved.</p>
        <div className="container-footer">
          <img className="symbol" src={mail} alt="mail" />
          <img className="symbol" src={twitter} alt="mail" />
          <img className="symbol" src={instagram} alt="mail" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
