import React from "react";

const ThankYouMessage = ({ selectedRating }) => {
  return (
    <div className={`text-red-600 text-xl font-semibold m-5`}>
      {selectedRating > 3
        ? "Thank you for your rating!"
        : "Thank you for your feedback."}
    </div>
  );
};

export default ThankYouMessage;
