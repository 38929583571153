import React from "react";

const FeedbackForm = ({ feedback, setFeedback, onSubmit }) => {
  return (
    <div className="mt-4 m-4">
      <textarea
        className="border p-2 w-full h-32 rounded-2xl"
        placeholder="We'd love to hear your pain, so we can help solve it"
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
      />
      <button
        onClick={onSubmit}
        className="mt-2 bg-red-500 text-white py-2 px-4 rounded-full"
      >
        Submit Feedback
      </button>
    </div>
  );
};

export default FeedbackForm;
