import React, { useState, useEffect, useRef } from "react";
import ProductsMini from "../Product/ProductsMini.jsx";
import Loader from "../Loader/Loader.jsx";
import { useHistory, useLocation } from "react-router-dom"; // Import useHistory and useLocation
import FilterDropDown from "./FilterDropDown.jsx";
import FilterDropDownCountries from "./FilterDropDown.jsx";
import useAppStore from "../../Store/Store.jsx"; // Import useAppStore to access the store
import RatingFaces from "../Rating/Rating.jsx";

function Filters(props) {
  // Destructure searchText and setSearchText from the store
  const { searchText, setSearchText, handleSearch } = useAppStore(); // Destructure handleSearch from the store
  const history = useHistory(); // Initialize useHistory

  // Handler for search text changes
  const handleSearchTextChange = (newSearchText) => {
    const encodedSearchText = encodeURIComponent(newSearchText);
    setSearchText(newSearchText);

    // handleSearch(newSearchText); // Trigger handleSearch function in the store
    history.replace(`/search?query=${encodedSearchText}`); // Redirect to search page with query params
  };

  // State for window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Ref for targeting specific component
  const targetComponentRef = useRef(null);
  // State for loading status
  const [isLoading, setIsLoading] = useState(false);

  // State for sorted products
  const [sortedProducts, setSortedProducts] = useState(props.gifts);
  // State for sorted products by country
  const [sortedCountryProducts, setSortedCountryProducts] = useState(
    props.gifts
  );
  // State for sort button label
  const [sortButtonLabel, setSortButtonLabel] = useState("Sort");
  // State for country button label
  const [countryButtonLabel, setCountryButtonLabel] = useState("Country");

  // Interval for scrolling into view
  useEffect(() => {
    const interval = setInterval(() => {
      if (targetComponentRef.current) {
        targetComponentRef.current.scrollIntoView({ behavior: "smooth" });
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [props.gifts]);

  // Handlers for sorting options
  const handleSortOption = (sortType) => {
    let sorted = [...sortedProducts];
    if (sortType === "priceAsc") {
      sorted.sort((a, b) => a.price - b.price);
      setSortButtonLabel("Price: Low to High");
    } else if (sortType === "priceDesc") {
      sorted.sort((a, b) => b.price - a.price);
      setSortButtonLabel("Price: High to Low");
    } else if (sortType === "loveAsc") {
      sorted.sort((a, b) => a.loveMeter - b.loveMeter);
      setSortButtonLabel("Love: Low to High");
    } else if (sortType === "loveDesc") {
      sorted.sort((a, b) => b.loveMeter - a.loveMeter);
      setSortButtonLabel("Love: High to Low");
    } else {
      setSortButtonLabel("Best Match");
    }
    setSortedProducts(sorted);
  };

  // Handlers for country filter options
  const handleCountryFilter = (country) => {
    let sorted = [...props.gifts].filter(
      (gift) =>
        gift.country?.toLowerCase() === country ||
        gift.country?.toLowerCase() === "worldwide"
    );
    setSortedProducts(sorted);
    setCountryButtonLabel(country);
    setSortButtonLabel("Sort");
  };

  // Options for sorting and country filters
  const sortOptions = [
    {
      id: 1,
      label: "Price: Low to High",
      action: () => handleSortOption("priceAsc"),
    },
    {
      id: 2,
      label: "Price: High to Low",
      action: () => handleSortOption("priceDesc"),
    },
    {
      id: 3,
      label: "Love: Low to High",
      action: () => handleSortOption("loveAsc"),
    },
    {
      id: 4,
      label: "Love: High to Low",
      action: () => handleSortOption("loveDesc"),
    },
    { id: 5, label: "Best Match", action: () => handleSortOption("bestMatch") },
  ];

  const countryOptions = [
    {
      id: 1,
      label: "UAE",
      action: () => handleCountryFilter("united arab emirates"),
    },
    { id: 2, label: "Germany", action: () => handleCountryFilter("germany") },
    { id: 3, label: "France", action: () => handleCountryFilter("france") },
    { id: 4, label: "All", action: () => handleCountryFilter("All") },
  ];

  // Resize event listener
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update sorted products when props.gifts changes
  useEffect(() => {
    setIsLoading(true);
    setSortedProducts(props.gifts);
    setSortedCountryProducts(props.gifts);
    setIsLoading(false);
  }, [props.gifts]);

  return (
    <div>
      <div>
        {/* <RatingFaces /> */}
        {sortedProducts.length > 0 && (
          <div className="flex justify-around mb-5">
            {/* <FilterDropDown
              buttonLabel={sortButtonLabel}
              options={sortOptions}
            /> */}
            {/* <FilterDropDownCountries
              buttonLabel={countryButtonLabel}
              options={countryOptions}
            /> */}
          </div>
        )}
        <div className="flex flex-wrap items-end justify-evenly mb-28 xl:w-full xl:justify-center sm:justify-evenly">
          {sortedProducts.map((gift) => (
            <div
              key={gift._id}
              className="products-mini-contain"
              ref={gift._id === 1 ? targetComponentRef : null}
            >
              <ProductsMini
                id={gift._id}
                opId={gift.opId}
                title={gift.title}
                image={gift.img}
                url={gift.link}
                price={gift.price}
                country={gift.country}
                description={gift.description}
                lovemeter={gift.loveMeter}
                rank={gift.rank}
                categories={gift.categories}
                handleSearchTextChange={handleSearchTextChange}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Filters;
