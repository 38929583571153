import "../Styles/TipTap.scss"; // Import custom styles for TipTap editor
import { EditorContent, useEditor } from "@tiptap/react"; // Import TipTap components
import StarterKit from "@tiptap/starter-kit"; // Import StarterKit extension for basic functionality
import Placeholder from "@tiptap/extension-placeholder"; // Import Placeholder extension
import React, { useEffect, useRef } from "react"; // Import React and necessary hooks

const MyEditorComponent = ({
  searchText, // Initial content of the editor
  setSearchText, // Function to update the content state
  handleSearchButtonClick, // Function to handle the search button click event
  focusedPlaceholder, // Placeholder text when editor is focused
  placeholders, // Array of placeholder texts
  isFocused, // Boolean indicating if the editor is focused
  placeholderIndex, // Index to select placeholder text from the array
}) => {
  const editorRef = useRef(null); // Reference to the editor

  // Function to handle content changes and apply custom formatting
  const handleProcedureContentChange = (content) => {
    const regex = /{.([^}]*).}/g; // Regular expression to find text between curly braces
    const formattedContent = content.replace(
      regex,
      "<span style='color: #BD4533;'><i>$1</i></span>" // Apply italic formatting and color change
    );

    setSearchText(formattedContent); // Update the content state
  };

  // Initialize the TipTap editor with necessary configurations and extensions
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: "Type something", // Configure placeholder based on focus state
      }),
    ],
    content: searchText || '', // Set initial content; ensure it's empty to show placeholder
    editorProps: {
      attributes: {
        spellcheck: "false", // Disable spellcheck
      },
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML(); // Get HTML content from the editor
      setSearchText(content); // Update the content state
      handleProcedureContentChange(content); // Apply custom formatting
    },
  });

  // Effect to update editor content when `searchText` changes
  useEffect(() => {
    if (editor && searchText !== editor.getHTML()) {
      editor.commands.setContent(searchText); // Set new content to the editor
    }
  }, [searchText, editor]);

  // Optional: Function to handle key events, such as pressing Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (!e.ctrlKey) {
        e.preventDefault(); // Prevent default Enter behavior
        handleSearchButtonClick(); // Trigger the search button click
      }
    }
  };

  // // Effect to add and remove the keydown event listener
  // useEffect(() => {
  //   if (editor) {
  //     editor.view.dom.addEventListener("keydown", handleKeyDown); // Add keydown event listener
  //   }
  //   return () => {
  //     if (editor) {
  //       editor.view.dom.removeEventListener("keydown", handleKeyDown); // Remove keydown event listener
  //     }
  //   };
  // }, [editor]);

  return (
    <div className="editor-wrapper">
      <EditorContent editor={editor} /> {/* Render the editor content */}
    </div>
  );
};

export default MyEditorComponent;

